import PropTypes from 'prop-types';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import Typography from '../Typography';
import { useRef, useCallback } from 'react';
import { Checkbox } from '../Checkbox';

const useStyles = makeStyles(({ palette }) => ({
  navItemContainer: {
    color: palette.secondary.darkBlue,
    fontSize: 14,
    letterSpacing: 0.15,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0px',
    borderRadius: '8px',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    width: '100%',
    '&:hover': {
      background: palette.neutrals.grayBlue,
    },
  },
  isHover: {
    background: palette.options.hover,
  },
  childItemWithMore: {
    padding: '0px',
  },
  childItem: {
    flex: 1,
    padding: '0px',
  },
  itemSelected: {
    fontWeight: 700,
    background: `${palette.options.hover} !important`,
  },
  labelWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  expandWrapper: {
    width: 'calc(100% - 24px)',
  },
  truncatedText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  fullWidth: {
    width: '100%',
  },
  moreIcon: {
    color: palette.secondary.darkBlue,
  },
}));

const TreeSubItem = ({
  isChild,
  isExpanded,
  isAllAccount,
  isSelected,
  label,
  isCheckbox,
  selectCategory,
  checked,
  match,
  onSelect,
  onToggleExpand,
  isHover,
  disabled,
}) => {
  const styles = useStyles();
  const clickTimeout = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
        onToggleExpand?.(e);
      } else {
        clickTimeout.current = setTimeout(() => {
          clickTimeout.current = null;
          onSelect?.(e);
        }, 5); // Adjust delay as needed
      }
    },
    [onSelect, onToggleExpand]
  );

  return (
    <div
      className={cx(styles.navItemContainer, {
        [styles.childItemWithMore]: !isAllAccount && isChild,
        [styles.childItem]: !isAllAccount && !isChild,
        [styles.itemSelected]: isSelected,
        [styles.isHover]: isHover,
      })}
      style={{ paddingLeft: 10 }}
      onClick={handleClick}
      id="sideNavigation_sideMenuItem"
    >
      <div
        className={cx(styles.labelWrapper, {
          [styles.expandWrapper]: !isAllAccount && isChild,
        })}
      >
        {(!isChild || selectCategory) && isCheckbox && (
          <Checkbox
            checked={checked}
            label={label}
            classes={{ formRoot: styles.fullWidth }}
            disabled={disabled}
          />
        )}
        <Typography
          variant={isAllAccount || (!match && isChild) || isSelected ? 'subtitle2' : 'body1'}
          match={match}
          className={styles.truncatedText}
        >
          {(!isCheckbox || isChild) && !selectCategory ? label : ''}
        </Typography>
      </div>
      {isChild && (
        <ExpandMoreIcon
          className={styles.moreIcon}
          style={{ transform: isExpanded && 'rotate(180deg)' }}
          onClick={(e) => {
            e.stopPropagation();
            onToggleExpand?.(e);
          }}
        />
      )}
    </div>
  );
};

TreeSubItem.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func,
};

TreeSubItem.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default TreeSubItem;
