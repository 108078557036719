import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment-timezone';
import { isEqual, omit, cloneDeep } from 'lodash';

import { attachmentApi, detailApi, organizationApi, taskLogApi, ticketApi } from '@/api';
import { userStore } from '@/store';
import {
  formatLocalDateTime,
  getAssignFromId,
  getCurrentLocalDateTime,
  getStateId,
  getStatusFromId,
  isValidQuantity,
  mapToUserOption,
  showErrorNotification,
  showSuccessNotification,
  sortMembers,
} from '@/utils';
import { ATTACHMENTS_FILED, ATTACHMENTS_PREFIX, PAGE_MODE, SCHEDULE_MODE } from '@/utils/constants';
import { setError } from '@/utils/errors';
import {
  AutomationStatusColors,
  ColumnType,
  HelpDeskStatus,
  NotificationText,
} from '@/utils/types';
import { parseCaseMessage } from '@/api/detail';
import { getWorkflowFiles, isEmptyConfig } from '@/utils/staticForm';
import { accountStore } from '@/store/AccountStore';
import { WORKFLOW_CONFIGS } from '@/utils/workflows';

const DEFAULT_COMMENT = { title: '', attachments: [] };
const DEFAULT_COLLAPSABLE = {
  configuration: true,
  discussions: false,
  schedules: true,
};

export class DetailContentStore {
  organizationInfo = null;
  parsedTicket = null;
  currentTicket = {};
  selectedId = null;
  familyTickets = null;
  activeTicketId = null;
  scheduleMode = SCHEDULE_MODE.none;
  isLoading = false;
  showFamilyTickets = false;
  assignOptions = [];
  isShowTicket = false;
  scheduleList = [];
  triggeredTickets = [];
  selectedSchedule = null;
  draftComment = DEFAULT_COMMENT;
  pageMode = PAGE_MODE.none;
  isCollapse = DEFAULT_COLLAPSABLE;

  constructor(taskLogStore) {
    makeObservable(this, {
      parsedTicket: observable,
      updateTicketInfo: action,
      organizationId: computed,
      setSelectedTicket: action,

      currentTicket: observable,
      reloadCurrentTicket: action,
      updateTicketField: action,
      setQuantity: action,
      setCompleted: action,

      selectedId: observable,
      canExecute: computed,

      isShowTicket: observable,

      organizationInfo: observable,
      orgPlatformList: computed,

      familyTickets: observable,
      setFamilyTickets: action,
      updateFamilyTicketsAssignee: action,

      showFamilyTickets: observable,
      setShowFamilyTickets: action,

      activeTicketId: observable,
      setActiveTicketId: action,

      scheduleList: observable,
      setScheduleList: action,

      triggeredTickets: observable,
      setTriggeredTickets: action,

      selectedSchedule: observable,
      setSelectedSchedule: action,
      selectedScheduleId: computed,

      scheduleMode: observable,
      setScheduleMode: action,

      assignOptions: observable,

      pageMode: observable,
      isCreating: computed,
      isEditing: computed,
      setPageMode: action,

      draftComment: observable,
      updateDraftComment: action,
      addComment: action,
      updateComment: action,
      deleteComment: action,

      isCollapse: observable,
      setIsCollapse: action,

      isLoading: observable,
      setIsLoading: action,
    });

    this.taskLogStore = taskLogStore;
    this.disposeAssignReaction = reaction(
      () => [this.organizationId],
      () => this.fetchOrganization(),
      { fireImmediately: true },
    );
  }

  async setSelectedTicket(ticketId, refresh = true) {
    this.isShowTicket = Boolean(ticketId);
    this.selectedId = ticketId;
    await this.setActiveTicketId(ticketId, refresh);
  }

  setScheduleList(value) {
    this.scheduleList = value;
  }

  setTriggeredTickets(value) {
    this.triggeredTickets = value;
  }

  get canExecute() {
    const { name, automationTime } = this.parsedTicket?.automation ?? {};
    const localAutomationTime = moment(automationTime);
    const currentTime = moment();
    // Calculate difference in milliseconds
    const differenceInMilliseconds = currentTime.diff(localAutomationTime);
    const differenceInSeconds = moment.duration(differenceInMilliseconds).asSeconds();

    if (
      userStore.isWFLAdminUser &&
      name === 'Failed' &&
      automationTime &&
      differenceInSeconds > 30
    ) {
      const { rerun } =
        userStore.workflowConfigList.find(({ value }) => value === this.parsedTicket.workflow) ??
        {};
      return !!rerun;
    } else {
      return false;
    }
  }

  async executeTicket() {
    if (!this.selectedId) return;
    try {
      const buildId = await taskLogApi.executeTicket(this.selectedId);

      runInAction(() => {
        this.parsedTicket.automation = {
          ...this.parsedTicket.automation,
          name: 'Queued',
          color: AutomationStatusColors.Queued,
          automationTime: getCurrentLocalDateTime(),
        };
        showSuccessNotification(`Build Id ${buildId} is running!`);
      });
    } catch (err) {
      setError(err, false, 'Execute ticket failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  get workflowConfig() {
    return this.currentTicket?.workflow;
  }

  get ticketId() {
    return this.parsedTicket?.id;
  }

  get discussions() {
    return this.parsedTicket?.discussions;
  }

  updateDraftComment(title = null, attachments = null) {
    if (title) {
      this.draftComment.title = title;
    }
    if (attachments) {
      this.draftComment.attachments = attachments;
    }
  }

  setSelectedTab(tab) {
    this.selectedTab = tab;
  }

  isSelectedTab(tab) {
    return this.selectedTab === tab;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  get organizationId() {
    return this.parsedTicket?.organizationId;
  }

  setHeight(value) {
    this.height = value;
  }

  setSelectedSchedule(value) {
    this.selectedSchedule = value;
  }

  get selectedScheduleId() {
    return this.selectedSchedule?.id;
  }

  setScheduleMode(value) {
    this.scheduleMode = value;
  }

  get isEditing() {
    return this.pageMode === PAGE_MODE.editing;
  }

  get isCreating() {
    return this.pageMode === PAGE_MODE.creating;
  }

  get workflowInfo() {
    return userStore.workflowConfigList.find(
      ({ workflow }) => workflow === this.currentTicket.workflow,
    );
  }

  setQuantity(value) {
    this.currentTicket.remainingWork = Number(value);
    const baseDays = this.workflowInfo?.baseDays;
    if (isValidQuantity(value) && baseDays) {
      const estimatedDays = (1 + Math.log10(value)) * baseDays;
      const estimatedDate = moment().add(estimatedDays, 'd');
      this.currentTicket.estimatedCompletion = formatLocalDateTime(estimatedDate);
    }
  }

  setCompleted(value) {
    this.currentTicket.completed = Number(value);
  }

  setPageMode(value) {
    this.pageMode = value;
  }

  setFamilyTickets(value) {
    if (value) {
      this.updateFamilyTicketsAssignee(value);
    } else {
      this.familyTickets = value;
    }
  }

  setShowFamilyTickets(value) {
    this.showFamilyTickets = value;
  }

  async setActiveTicketId(value, refresh = false) {
    this.activeTicketId = value;
    if (value) {
      this.setPageMode(PAGE_MODE.none);
      await this.fetchTicket(this.activeTicketId, refresh);
    } else {
      this.organizationInfo = null;
    }
  }

  get orgPlatformList() {
    if (!userStore.platformTypes?.length) return [];
    return (this.organizationInfo?.profile?.logins ?? []).map(
      ({ platform }) => userStore.platformTypes.find(({ id }) => id === platform)?.name ?? platform,
    );
  }

  async fetchTicket(ticketId, refresh = false) {
    this.setIsLoading(true);
    try {
      if (refresh) this.setFamilyTickets(null);
      const { ticketInfo, familyTickets, scheduleList, triggeredTickets } =
        await detailApi.getTicketDetails(ticketId);

      runInAction(() => {
        const configFiles = getWorkflowFiles(ticketInfo.workflow, ticketInfo.config);
        this.setScheduleList(scheduleList ?? []);
        this.setTriggeredTickets(triggeredTickets ?? []);
        this.setFamilyTickets(familyTickets);
        const organizationInfo = accountStore.getAccountFromId(
          accountStore.allAccounts,
          ticketInfo.organizationId,
        );
        this.updateTicketInfo({
          ...ticketInfo,
          state: getStatusFromId(ticketInfo.stateId),
          organization: organizationInfo?.name,
          configFiles,
        });
      });
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
    this.setIsLoading(false);
  }

  async updateAttachments(data) {
    const newAttachments = data.attachments;
    if (newAttachments && !isEqual(this.parsedTicket.attachments, newAttachments)) {
      const attachmentsDiscussionId = this.parsedTicket.attachmentsDiscussionId;
      if (!attachmentsDiscussionId) {
        const formData = new FormData();
        formData.append('comment', `${ATTACHMENTS_PREFIX}${this.ticketId}]`);
        newAttachments.forEach((file) => {
          formData.append(ATTACHMENTS_FILED, file);
        });
        await ticketApi.addComment(this.ticketId, formData);
      } else {
        await this.parsedTicket.attachments
          .filter((file) => !newAttachments.find((item) => item.id === file.id))
          .reduce(
            (callback, attachment) =>
              callback.then(async () => {
                await attachmentApi.deleteAttachment(attachment.id);
              }),
            Promise.resolve(),
          );
        await newAttachments
          .filter((file) => !file.id)
          .reduce(
            (callback, file) =>
              callback.then(async () => {
                await attachmentApi.addAttachment(attachmentsDiscussionId, file);
              }),
            Promise.resolve(),
          );
        if (newAttachments.length === 0) {
          await ticketApi.deleteComment(attachmentsDiscussionId);
        }
      }
    }
  }

  reloadCurrentTicket() {
    this.currentTicket = cloneDeep(this.parsedTicket ?? {});
  }

  updateTicketField(fieldValue) {
    const field = Object.keys(fieldValue)[0];
    this.currentTicket[field] = fieldValue[field];
  }

  async updateTicket(data, templateFiles, resultFiles) {
    this.setIsLoading(true);
    let result = false;
    try {
      const configFiles = await Promise.all(
        Object.entries(templateFiles ?? {}).map(async ([key, file]) => {
          if (!file || file.hasWorkflowContent) return { [key]: file?.name };
          const filename = await attachmentApi.uploadAttachment(file);
          return { [key]: filename };
        }),
      );
      const resultValue = await Promise.all(
        Object.entries(resultFiles ?? {}).map(async ([key, file]) => {
          if (!file || file.hasWorkflowContent) return { [key]: file?.name };
          const filename = await attachmentApi.uploadAttachment(file);
          return { [key]: filename };
        }),
      );
      const resultAttachments = resultValue.reduce((acc, value) => ({ ...acc, ...value }), {});
      const configAttachments = configFiles.reduce((acc, value) => ({ ...acc, ...value }), {});
      const config = !isEmptyConfig(data.config)
        ? JSON.stringify({
            ...omit(data.config, WORKFLOW_CONFIGS[this.workflowConfig].attachments ?? ''),
            ...configAttachments,
          })
        : null;
      const ticketResult = !isEmptyConfig(resultAttachments)
        ? JSON.stringify(resultAttachments)
        : null;
      const savingData = {
        title: data.title,
        description: data.description,
        ownedBy: data.ownedBy,
        assignedTo: data.assignedTo,
        priority: data.priority,
        stateId: getStateId(data.state),
        quantity: data.remainingWork,
        completed: data.completed,
        config,
        result: ticketResult,
      };
      await ticketApi.updateTicket(data.ticketId, savingData);
      await this.updateAttachments(data);

      runInAction(() => {
        const assign = getAssignFromId(data.assignedTo, this.assignOptions);
        const owner = getAssignFromId(data.ownedBy, this.assignOptions);
        this.updateTicketInfo({
          ...data,
          config: config && JSON.parse(config),
          [ColumnType.assignedName]: assign?.label,
          [ColumnType.ownedByName]: owner?.label,
          result: ticketResult && JSON.parse(ticketResult),
        });
        this.familyTickets = this.familyTickets.map((ticket) =>
          ticket.ticketId === data.ticketId
            ? { ...this.parsedTicket, assignedToType: assign?.type, assignedToName: assign?.label }
            : ticket,
        );
        this.taskLogStore.updateTicketLogs(this.parsedTicket);
        result = true;
      });
      showSuccessNotification(NotificationText.saveTicketSuccess);
    } catch (err) {
      setError(err, false, NotificationText.updateTaskItemError);
      showErrorNotification(NotificationText.updateTaskItemError);
    }
    this.setIsLoading(false);
    return result;
  }

  async updateStatus(newStatus) {
    const newData = { ...this.parsedTicket, state: newStatus, stateId: getStateId(newStatus) };
    await this.updateTicket(newData);
    if (newStatus === HelpDeskStatus.closed || newStatus === HelpDeskStatus.canceled) {
      const ticketId = this.parsedTicket.ticketId;
      this.familyTickets = this.familyTickets.map((ticket) => {
        if (!ticket.ticketPath.includes(ticketId)) return ticket;
        const newTicket = {
          ...ticket,
          state: newStatus,
          stateId: getStateId(newStatus),
        };
        this.taskLogStore.updateTicketLogs(newTicket);
        return newTicket;
      });
    }
  }

  async updateOwner(newOwnerId) {
    const newData = { ...this.parsedTicket, ownedBy: newOwnerId };
    await this.updateTicket(newData);
  }

  async updateAssign(newAssignId) {
    const newData = { ...this.parsedTicket, assignedTo: newAssignId };
    await this.updateTicket(newData);
  }

  async saveSchedule(schedule) {
    try {
      const newSchedule = await ticketApi.saveSchedule(schedule);
      runInAction(() => {
        if (schedule.id) {
          const idx = this.scheduleList.findIndex((s) => s.id === schedule.id);
          if (idx > -1) {
            this.scheduleList[idx] = newSchedule;
          }
        } else {
          this.scheduleList = [...this.scheduleList.slice(0, -1), newSchedule];
        }
      });
      return newSchedule;
    } catch (err) {
      setError(err, true, `Failed to ${schedule.id ? 'save' : 'create'} schedule`);
    }
  }

  async deleteSchedule(scheduleId) {
    try {
      await ticketApi.deleteSchedule(scheduleId);
      runInAction(() => {
        this.scheduleList = this.scheduleList.filter((s) => s.id !== scheduleId);
      });
    } catch (err) {
      setError(err, true, `Failed to delete schedule`);
    }
  }

  updateFamilyTicketsAssignee(value) {
    const familyTickets = value ?? this.familyTickets;
    this.familyTickets = familyTickets?.map((ticket) => {
      const assign = getAssignFromId(ticket.assignedTo, this.assignOptions);
      return {
        ...ticket,
        state: getStatusFromId(ticket.stateId),
        assignedToType: assign?.type,
        assignedToName: assign?.label,
      };
    });
  }

  updateTicketInfo(value) {
    const ticketInfo = value ?? this.parsedTicket;
    this.parsedTicket = {
      ...ticketInfo,
      discussions: ticketInfo.discussions?.map((discussion) =>
        parseCaseMessage(discussion, this.assignOptions),
      ),
      assignee: getAssignFromId(ticketInfo.assignedTo, this.assignOptions),
      createdByName: getAssignFromId(ticketInfo.createdById, this.assignOptions)?.label,
    };
    this.reloadCurrentTicket();
  }

  async fetchOrganization() {
    if (!this.organizationId) return;
    try {
      await userStore.fetchLookupsInfo(this.organizationId);
      const organizationInfo = await organizationApi.getOrganization(this.organizationId);
      runInAction(() => {
        this.organizationInfo = organizationInfo;
        const assigns = sortMembers(userStore.lookupsInfo.assignees);
        this.assignOptions = assigns.map(mapToUserOption);
        this.updateFamilyTicketsAssignee();
        this.updateTicketInfo({
          ...this.parsedTicket,
          state: getStatusFromId(this.parsedTicket.stateId),
        });
      });
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
  }

  setIsCollapse(fieldValue) {
    this.isCollapse = { ...DEFAULT_COLLAPSABLE, discussions: true, ...fieldValue };
  }

  async fetchMoveTicket(ticketId, toParentId) {
    this.setIsLoading(true);
    let result;
    try {
      result = await ticketApi.moveTicket(ticketId, toParentId);
    } catch (err) {
      setError(err, false, 'Move ticket tree failed');
    }
    if (result) {
      try {
        await this.fetchTicket(ticketId);
      } catch (err) {
        setError(err, false, 'Refresh ticket tree failed');
      }
    }
    this.setIsLoading(false);
  }

  // NOTE: Comment
  async addComment(type, ticketId, comment, attachments) {
    this.setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('comment', comment || '');
      attachments.forEach((file) => {
        formData.append(ATTACHMENTS_FILED, file);
      });
      await ticketApi.addComment(ticketId, formData);
      this.fetchTicket(this.ticketId);
      runInAction(() => {
        this.draftComment = DEFAULT_COMMENT;
      });
    } catch (err) {
      setError(err, false, NotificationText.addCommentError);
    }
    this.setIsLoading(false);
  }

  async updateComment(discussionId, comment, addingFiles, removingFiles) {
    this.setIsLoading(true);
    try {
      await addingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.addAttachment(discussionId, file);
          }),
        Promise.resolve(),
      );
      await removingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.updateComment(discussionId, comment);

      await this.fetchTicket(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.updateCommentError);
      showErrorNotification(NotificationText.updateCommentError);
    }

    this.setIsLoading(false);
  }

  async deleteComment(commentId, files, isRequest = false) {
    if (!commentId) return;
    this.setIsLoading(true);
    try {
      await files.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.deleteComment(commentId);

      await this.fetchTicket(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.deleteCommentError);
      showErrorNotification(NotificationText.deleteCommentError);
    }
    this.setIsLoading(false);
  }

  dispose() {
    this.disposeAssignReaction();
  }
}
