import { DEFAULT_CRONS } from '@/utils/constants';
import { AutomationStatusIcons, ColumnType } from '../../../utils/types';
import { formatLocalDateTime, getMatch, SHORT_DATE_FORMAT } from '@/utils';
import { AutomationLog } from '@/components/Table/AutomationLog';
import { TicketLinkCell } from '@/components/Table';
import { Chip, Typography } from '@/components';

const getDateValue = (value) => (value ? formatLocalDateTime(value, 'MM/DD/YYYY') : '');

export const SCHEDULE_COLUMNS = [
  {
    field: ColumnType.cron,
    headerName: 'Cron',
    type: 'singleSelect',
    valueOptions: DEFAULT_CRONS,
    editable: true,
    maxWidth: 360,
    flex: 1,
    sortable: false,
  },
  {
    field: ColumnType.paused,
    headerName: 'Paused',
    renderCell: ({ row }) => (row.paused ? '✓' : ''),
    type: 'singleSelect',
    valueOptions: [true, false],
    width: 84,
    editable: true,
    sortable: false,
  },
  {
    field: ColumnType.lastRun,
    headerName: 'Last Run',
    type: 'date',
    valueFormatter: getDateValue,
    width: 120,
    sortable: false,
  },
  {
    field: ColumnType.createdOn,
    headerName: 'Created Date',
    type: 'date',
    valueFormatter: getDateValue,
    width: 120,
    sortable: false,
  },
];

export const TRIGGERS_COLUMNS = [
  {
    field: ColumnType.id,
    headerName: 'Id',
    width: 72,
    sortable: false,
  },
  {
    field: ColumnType.state,
    headerName: 'Status',
    width: 100,
    sortable: false,
  },
  {
    field: ColumnType.title,
    headerName: 'Title',
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: ColumnType.createdOn,
    headerName: 'Triggered On',
    width: 112,
    sortable: false,
    valueFormatter: getDateValue,
  },
  {
    field: ColumnType.automation,
    headerName: 'Automation',
    sortable: false,
    width: 100,
    renderCell: ({ value: automation }) => <AutomationLog automation={automation} isAdminUser />,
  },
];

export const TICKETS_COLUMNS = (search) => [
  {
    field: ColumnType.id,
    sortKey: 't.id',
    headerName: 'ID',
    width: 80,
    renderCell: (params) => (
      <TicketLinkCell
        column={ColumnType.id}
        link={params.row.ticketUrl}
        value={params.row[ColumnType.id]}
      />
    ),
  },
  {
    field: ColumnType.state,
    sortKey: 'state',
    headerName: 'State',
    width: 120,
    renderCell: ({ value }) => <Chip variant={value} />,
  },
  {
    field: ColumnType.accountName,
    sortKey: 'organization',
    headerName: 'Organization',
    width: 200,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: ColumnType.intentType,
    sortKey: 'workflow',
    headerName: 'Service',
    width: 180,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: ColumnType.title,
    sortKey: 'title',
    headerName: 'Title',
    width: 300,
    renderCell: ({ value }) => (
      <Typography variant="body2" match={getMatch(value, search)}>
        {value}
      </Typography>
    ),
  },
  {
    field: ColumnType.createdByName,
    sortKey: 'u1.name',
    headerName: 'Creator',
    width: 150,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: ColumnType.ownedByName,
    sortKey: 'u2.name',
    headerName: 'Owner',
    width: 150,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: ColumnType.assignedName,
    sortKey: 'u3.name',
    headerName: 'Assigned To',
    width: 150,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: ColumnType.createdDate,
    sortKey: 't.createdOn',
    headerName: 'Created',
    width: 120,
    renderCell: ({ value }) => (
      <Typography variant="caption">{formatLocalDateTime(value, SHORT_DATE_FORMAT)}</Typography>
    ),
  },
  {
    field: ColumnType.lastUpdated,
    sortKey: 'UpdatedOn',
    headerName: 'Updated',
    width: 120,
    renderCell: ({ value }) => (
      <Typography variant="caption">{formatLocalDateTime(value, SHORT_DATE_FORMAT)}</Typography>
    ),
  },
  {
    field: ColumnType.automation,
    sortKey: 'Automation',
    headerName: 'Automation',
    width: 100,
    renderCell: ({ value }) => <AutomationLog automation={value} isAdminUser />,
  },
  {
    field: ColumnType.buildError,
    sortKey: 'BuildError',
    headerName: 'Build Error',
    flex: 1,
    minWidth: 200,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
];
