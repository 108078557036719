import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/material';

import { TemplateAttachment } from '@/components';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { withStore } from '@/store';
import { WORKFLOW_CONFIGS } from '@/utils/workflows';
import MultipleAttachments from './MultipleAttachments';

export const WorkflowResultForm = withStore(
  observer(({ showWarning, disabled }) => {
    const { workflowStore } = useMainStore();
    const configName = workflowStore.workflow;

    return (
      <Stack spacing={1} pl={2} pt={1.5}>
        {Object.entries(WORKFLOW_CONFIGS[configName].result).map(
          ([attachmentField, attachment]) => {
            return Array.isArray(attachment) ? (
              <MultipleAttachments
                label={attachmentField}
                files={attachment}
                setFiles={(files) => workflowStore.setResultFiles(attachmentField, files)}
                warning={showWarning && !attachment.length}
                disabled={disabled}
              />
            ) : (
              <TemplateAttachment
                key={attachmentField}
                workflowType={workflowStore.workflow}
                templateFile={workflowStore.resultFiles[attachmentField]}
                onUpdateFile={(file) => workflowStore.setResultFiles(attachmentField, file)}
                label={attachmentField}
                showWarning={showWarning}
                noTemplate
                disabled={disabled}
              />
            );
          },
        )}
      </Stack>
    );
  }),
);
