import { useEffect, useState } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography, Avatar, Chip } from '@/components';
import { DragIcon } from '@/assets/icons/DragIcon';
import { AvatarTooltip } from '../Avatar/AvatarTooltip';
import { ObjectType } from '@/utils/types';
import { AutomationLog } from '../Table/AutomationLog';
import { getTicketLink } from '@/utils';
import { Link } from 'react-router-dom';
import { AmazonChip } from '../Chip/Chip';

const useStyles = makeStyles((theme) => ({
  taskBulletContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.neutrals.grayBlue,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  taskBulletContent: {
    height: '100%',
    padding: '4px 0px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    '&:hover': {
      background: '#ECEDF8',
    },
  },
  selected: {
    background: `${theme.palette.options.hover} !important`,
  },
  cantDrop: {
    background: `${theme.palette.neutrals.lightGrey} !important`,
  },
  activeDrop: {
    background: `${theme.palette.options.hover} !important`,
  },
  taskBulletItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  truncatedText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  redDot: {
    marginTop: '7px',
    minWidth: '10px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: theme.palette.ui.error,
  },
  assign: {
    fontSize: '10px !important',
  },
  bottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 0,
    zIndex: 2,
    borderBottom: '1px solid rgba(211, 211, 211, 0.4)',
  },
  requestBottom: {
    left: '22px !important',
  },
  phaseBottom: {
    left: '49px !important',
  },
  taskBottom: {
    opacity: 0,
  },
  moreIcon: {
    color: theme.palette.secondary.darkBlue,
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
  topShadow: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    width: '100%',
    height: '1px',
    background: 'rgba(211, 211, 211, 0.4)',
  },
  bottomShadow: {
    position: 'absolute',
    bottom: '-1px',
    zIndex: 2,
    width: '100%',
    height: '1px',
    background: 'rgba(211, 211, 211, 0.4)',
  },
}));

const PAGE_NAME = 'TaskDetail_TaskBullet';
const INTENT_WIDTH = 20;
const DRAG_ICON_WIDTH = 24;

const TaskBullet = ({
  organizationId,
  assignerCompanyName,
  automation,
  variant,
  ticketId,
  ticketPath,
  isDragMode,
  selected,
  title,
  assignType,
  assignName,
  assignBackground,
  assignForeColor,
  status,
  externalStatus,
  generation = -1,
  onClick,
  onMoveTicket,
}) => {
  const [isHover, setIsHover] = useState(false);
  const styles = useStyles();
  const intendWidth = INTENT_WIDTH * Math.max(generation, 0);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ObjectType.task,
      drop: (item, monitor) => {
        onMoveTicket(item.id, ticketId);
      },
      canDrop: (item) =>
        item.id !== ticketId && !ticketPath.includes(item.id) && item.parent !== ticketId,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [ticketId, ticketPath],
  );
  const { isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
  }));

  const isTask = ticketPath?.length > 1;
  const [{ isThisDragging }, drag, preview] = useDrag(
    () => ({
      type: isTask ? ObjectType.task : ObjectType.request,
      item: {
        id: ticketId,
        parent: ticketPath?.[ticketPath.length - 2],
        ticketPath,
      },
      collect: (monitor) => ({
        isThisDragging: !!monitor.isDragging(),
      }),
    }),
    [ticketId],
  );
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      ref={(node) => (isDragMode ? drop(node) : node)}
      className={styles.taskBulletContainer}
      id={`${PAGE_NAME}_Container`}
    >
      <div className={styles.topShadow} />
      <div
        className={cx(styles.taskBulletContent, {
          [styles.selected]: selected && !isDragging,
          [styles.cantDrop]: isOver && !canDrop,
          [styles.activeDrop]: canDrop && isOver,
        })}
        onMouseEnter={() => setIsHover(isDragMode && isTask)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
      >
        <div
          className={styles.taskBulletItem}
          style={{
            marginLeft: intendWidth,
            width: `calc(100% - ${intendWidth + DRAG_ICON_WIDTH}px)`,
          }}
        >
          <div className={styles.row}>
            <Typography variant="body2">
              <Link to={getTicketLink(ticketId)}>{ticketId}</Link>
            </Typography>
            <Typography variant="subtitle2" className={styles.truncatedText}>
              {title}
            </Typography>
            <AutomationLog automation={automation} smallSize isAdminUser />
          </div>
          <div className={styles.row}>
            <Typography variant="body2" className={styles.truncatedText}>
              {variant}
            </Typography>
            <Chip variant={status} />
            {externalStatus && <AmazonChip status={externalStatus} />}
            <Avatar
              name={assignName}
              width={20}
              variant={assignType}
              bgColor={assignBackground}
              foreColor={assignForeColor}
              className={styles.assign}
              tooltipArrow={false}
              tooltipText={<AvatarTooltip name={assignName} companyName={assignerCompanyName} />}
            />
          </div>
        </div>
        {isHover && (
          <div ref={(node) => (isDragMode ? drag(node) : node)} style={{ cursor: 'move' }}>
            <DragIcon style={{ opacity: 0 }} />
          </div>
        )}
      </div>
      <div className={styles.bottomShadow} />
    </div>
  );
};

TaskBullet.propTypes = {
  assignerCompanyName: PropTypes.string,
  assignerRoleName: PropTypes.string,
  variant: PropTypes.string.isRequired,
  isDot: PropTypes.bool,
  isPinned: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  isDragMode: PropTypes.bool,
};

TaskBullet.defaultProps = {
  assignerCompanyName: '',
  isDot: false,
  isPinned: false,
  isDragMode: false,
  assignerRoleName: '',
};

export default TaskBullet;
