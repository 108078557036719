import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';
import 'brace/mode/json'; // Import the JSON mode
import 'brace/theme/monokai'; // Import the Monokai theme
import { InfoOutlined } from '@mui/icons-material';
import { omit } from 'lodash';

import { IconTabs, TextLink, Typography } from '@/components';
import { WORKFLOW_CONFIGS } from '@/utils/workflows';
import { CONFIG_TABS } from '@/utils/constants';
import { useMainStore } from '../../stores/useMainStore';

import styles from './DetailWorkflowContent.module.css';
import { WorkflowResultForm } from './WorkflowResultForm';

const JSON_LINE_HEIGHT = 17;
const JSON_EDITOR_PADDING = 28;
const tabs = [
  { label: CONFIG_TABS.form, value: true },
  { label: CONFIG_TABS.json, value: false },
];
const INVISIBLE_FIELDS = ['$type', 'Success'];

export const DetailWorkflowContent = observer(({ config, result, isEditing, workflowName }) => {
  const { workflowStore, detailStore } = useMainStore();
  const [SpecificForm, setSpecificForm] = useState(null);
  const jsonStr = JSON.stringify(omit(config, INVISIBLE_FIELDS), null, 2);
  const height = `${jsonStr.split('\n').length * JSON_LINE_HEIGHT + JSON_EDITOR_PADDING}px`;
  const showResult = Boolean(WORKFLOW_CONFIGS[workflowName]?.result);
  const jsonResult = JSON.stringify(omit(result, INVISIBLE_FIELDS), null, 2);
  const resultsHeight = `${
    jsonResult.split('\n').length * JSON_LINE_HEIGHT + JSON_EDITOR_PADDING
  }px`;
  const resultHelpLink =
    'https://www.notion.so/Welcome-to-Workflow-Labs-1fa7a55313094f23b4553afbf2d512c5';
  const configHelpLink =
    'https://www.notion.so/Welcome-to-Workflow-Labs-1fa7a55313094f23b4553afbf2d512c5';
  const showForm = workflowStore.showForm;

  useEffect(() => {
    if (workflowName) {
      import(`./SpecificForms/${workflowName}`)
        .then((module) => {
          setSpecificForm(() => module[workflowName]);
        })
        .catch(() => {
          setSpecificForm(null);
        });
      workflowStore.reload(config);
      workflowStore.reloadResult(result);
    }
  }, [workflowName]);

  if (detailStore.isCollapse.configuration) return null;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.row}>
          <Typography variant="caption-bold">Configuration</Typography>
          <TextLink href={configHelpLink}>
            <InfoOutlined fontSize="small" className={styles.helpIcon} />
          </TextLink>
        </div>
        <IconTabs
          activeColor=""
          tabArray={tabs}
          value={showForm}
          flexContainer={styles.row}
          onChange={(_, newValue) => workflowStore.setShowForm(newValue)}
        />
      </div>
      {showForm && Boolean(SpecificForm) && (
        <SpecificForm config={config} disabled={!isEditing} showWarning />
      )}
      {!showForm && (
        <AceEditor
          mode="json"
          theme="monokai"
          readOnly
          name="json-editor"
          value={jsonStr}
          editorProps={{ $blockScrolling: true }}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine
          fontSize={14}
          height={height}
          style={{ borderRadius: '4px', width: 'calc(100% - 16px)', marginLeft: '16px' }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          enableSnippets={false}
          tabSize={2}
        />
      )}
      {showResult && (
        <>
          <div className={styles.row}>
            <Typography variant="caption-bold">Results</Typography>
            <TextLink href={resultHelpLink} className={styles.helpLink}>
              <InfoOutlined fontSize="small" className={styles.helpIcon}/>
            </TextLink>
          </div>
          {showForm && <WorkflowResultForm disabled={!isEditing} showWarning />}
          {!showForm && (
            <AceEditor
              mode="json"
              theme="monokai"
              readOnly
              name="json-editor"
              value={jsonResult}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine
              fontSize={14}
              height={resultsHeight}
              style={{ borderRadius: '4px', width: 'calc(100% - 16px)', marginLeft: '16px' }}
              enableBasicAutocompletion={false}
              enableLiveAutocompletion={false}
              enableSnippets={false}
              tabSize={2}
            />
          )}
        </>
      )}
    </>
  );
});
