export const WORKFLOW_CONFIGS = {
  ManageOrganization: {
    id: '9b29701f-f764-4fcb-bf46-1720ae289a7f',
    category: 'Administrative',
    baseDays: 4,
    template: {
      New: null,
      Update: null,
    },
    description:
      'Add a new organization by providing the name of the Brand. Use the Description Field to remove or Change an Organization. Resolvers will provide the next steps to connect to Amazons platforms.',
    title: 'Manage Organization',
  },
  ManageUser: {
    id: 'd61d43b1-9c57-4ec9-864f-e7be4ae8b24e',
    category: 'Administrative',
    baseDays: 4,
    template: {
      Onboard: null,
      Update: null,
      Offboard: null,
    },
    description:
      'Add a new user or Delete a user. Provide a name, and email address for the new user.',
    title: 'Manage Users',
  },
  AuditCatalog: {
    id: 'd211500f-01fe-4fb4-b456-d403e8229bd8',
    category: 'Catalog',
    rerun: true,
    baseDays: 4,
    template: {
      ProductsFilename: null,
      TriggerFixes: false,
    },
    attachments: ['ProductsFilename'],
    description:
      'Will create a comparison of product data you supply, to live detail page product data (title, description, bullets, image count, and variation ASINs count). Supply to us the source of truth. We will compare it to live detail page data and make any necessary changes.',
    title: 'Audit Catalog',
    hasSpecificData: true,
    result: {
      ActionableProducts: '',
      RainforestProducts: '',
      SuccessfulComparisons: '',
      FailedComparisons: '',
      ProductsJson: '',
      ContentUpdates: '',
    },
  },
  UnauthorizedOffers: {
    id: '2ae4a00f-2283-441b-b6ea-cf09dcc18d9d',
    category: 'Catalog',
    baseDays: 4,
    template: {
      Email: '',
      Name: '',
      UnauthorizedList: [],
    },
    description:
      'If you have invited us to Brand Registry with Brand Owner Permissions for the client, we can remove unauthorized offers. You will have to provide the ASIN and a contact at the brand that we will give to Amazon. They may be contacted by the removed seller. The request is resolved when the seller offer is removed.',
    title: 'Unauthorized Offers',
  },
  UnsuppressProduct: {
    id: 'a901901d-92b4-4e51-90c2-730691a6f4af',
    category: 'Catalog',
    rerun: true,
    baseDays: 4,
    template: {},
    description:
      'Create the request to have Amazon re-instate a suppressed offer. Provide the product identifier and any context about the suppression that you have. The request is resolved when the offer is once again available to customers.',
    title: 'Unsuppress Product',
  },

  ChannelFeedback: {
    id: '33581a85-eee9-4939-a057-d4c630829853',
    category: 'Content',
    baseDays: 4,
    template: {},
    description:
      'Use this to provide a Salsify Channel Feedback report to have resolvers clear the errors so that content can be syndicated by Salsify',
    title: 'Channel Feedback',
  },
  KeywordRecommendation: {
    id: '6af8a444-8b39-4763-b2f6-75bac3862f16',
    category: 'Content',
    baseDays: 4,
    template: {
      Asin: null,
      ProductType: null,
    },
    description:
      'Use this to generate a list of the top 100 keywords for an ASIN or for a product type.',
    title: 'Keyword Recommendation',
  },
  ManageContent: {
    id: 'aba666b1-9b79-4776-8086-07a1d9675869',
    category: 'Content',
    baseDays: 4,
    template: {
      ProductsFilename: null,
    },
    attachments: ['ProductsFilename'],
    description: 'Change titles, bullets, and descriptions for up to 25 ASINs.',
    title: 'Manage Content',
    result: {
      BlockedUpdatesFile: '',
      IncompleteBulletsFile: '',
      ProductUpdateBatchFiles: [],
    },
  },
  ManageVariation: {
    id: 'f9cb0142-6272-45e8-87f1-9f4798b50027',
    category: 'Content',
    baseDays: 4,
    template: {
      VariationFiles: [],
    },
    attachments: ['VariationFiles'],
    description:
      'Create, change, or break a variation. Provide the Product Identifiers and the groupings you want. If no groupings are provided, we will make the variation in the optimal way following Amazon guidelines. The request will resolve after confirming the changes are live to customers.',
    title: 'Manage Variation',
  },
  NewItem: {
    id: '02ce4d93-4ec2-47af-be75-8276b312e55b',
    category: 'Content',
    baseDays: 4,
    template: {},
    description:
      'Attach the product information to create new items or offers. Resolvers will fill in Amazon’s template and have you approve our work before uploading to Amazon. The request resolves after confirming the new products are ready for the retailer to order or receive shipments.',
    title: 'New Item',
  },

  AskQuestion: {
    id: 'aaba28e1-5513-4aad-baec-34dbd7b252b1',
    category: 'General',
    baseDays: 4,
    template: {},
    description: 'Go ahead and ask Amazon experts… anything.',
    title: 'Ask a Question',
  },
  ManageExternalCase: {
    id: '49c30107-da18-4486-bbfa-93afa08b2437',
    category: 'General',
    rerun: true,
    baseDays: 2,
    template: {
      PlatformType: 0,
      Get: null,
      Create: null,
    },
    description:
      'Use this to add a contact you’ve initiated with Amazon and have resolvers follow up. In Vendor Central, you will have to CC the email that has access to the account for us to be able to pull in the case detail.',
    title: 'Manage External Case',
    result: {
      MessagesFile: '',
    },
  },
  OtherService: {
    id: 'eaebffff-226e-4ff7-8720-f93da83d2fa0',
    category: 'General',
    baseDays: 4,
    template: {},
    description:
      'Use this to create a Request that you can assign to anyone in your own organization, or make a request that is not one of the types provided.',
    title: 'Other Service',
  },

  CreateShipment: {
    id: 'd9b151b8-57f6-4430-987c-e97acfa39dc0',
    category: 'Operations',
    baseDays: 4,
    template: {},
    description: 'Create shipments in Seller Central for you to fill',
    title: 'Create Shipment',
  },
  DisputeInvoiceShortage: {
    id: '6d4dac28-4274-4b11-b127-afbfc467ef80',
    category: 'Operations',
    baseDays: 4,
    template: {},
    description:
      'Dispute invoice shortages, and create contacts with Amazon to recover shorted invoice money, summarizing the amount recovered in the request. You will need to provide BOLs.',
    title: 'Dispute Invoice',
  },
  InventoryReconciliation: {
    id: 'd0ad2ffa-67fe-47d2-aa87-a2e6666331d9',
    category: 'Operations',
    baseDays: 4,
    template: {},
    description:
      'Use this to provide a reconciliation report and to process and dispute all the discrepancies.',
    title: 'Inventory Reconciliation',
  },
  ShipmentReconciliation: {
    id: 'ab65a9a6-0bcb-492a-9162-86f89bce66e3',
    category: 'Operations',
    baseDays: 4,
    template: {},
    description:
      'Use this to provide a reconciliation report and to process and dispute all the discrepancies. You will need to provide proof of inventory.',
    title: 'Shipment Reconciliation',
    result: {
      ShipmentReconciliation: '',
    },
  },
  WeeksOfCover: {
    id: '711b53c7-0365-41a5-b831-9f3d5c4d89a7',
    category: 'Operations',
    rerun: true,
    baseDays: 4,
    template: {
      SalesSourcingReportP1: null,
      SalesSourcingReportP2: null,
      SalesSourcingReportP3: null,
      InventorySourcingReport: null,
      PurchaseOrderPoItemsReport: null,
      AvailabilityReport: null,
    },
    attachments: [
      'SalesSourcingReportP1',
      'SalesSourcingReportP2',
      'SalesSourcingReportP3',
      'InventorySourcingReport',
      'PurchaseOrderPoItemsReport',
      'AvailabilityReport',
    ],
    description:
      'Provides relevant reporting to determine inventory position and run rate. Includes weighted average weekly sales over a 90-day period and directional sales trends. Only available for US based stores.',
    title: 'Weeks Of Cover',
    result: {
      WeeksOfCoverFile: '',
    },
  },
  Billing: {
    id: '58621567-680b-4225-98e5-29c96a917eb7',
    category: 'Reporting',
    rerun: true,
    baseDays: 4,
    template: {},
    description: 'Will provide a report of the requests made in the billing period.',
    title: 'Billing',
  },
  // RetailReadiness: {
  //   id: '8956ac93-7574-4138-9c9d-e3873580a025',
  //   category: 'Reporting',
  //   rerun: true,
  //   baseDays: 4,
  //   template: {},
  //   result: {
  //     PurchaseOrdersFile: '',
  //   },
  // },
  TicketsSummary: {
    id: '58621567-680b-4225-98e5-29c96a917dc7',
    category: 'Reporting',
    rerun: true,
    baseDays: 4,
    template: {},
    description:
      "Will provide a report of the client's tickets created or resolved in the last 91 days.",
    title: 'Tickets Summary',
    result: {
      MasterTable: '',
      // ResolvedInLessThanTwo: '',
      // ResolvedTimesByService: '',
      // ResolvedRequestSummary: '',
      // CreatedByWeek: '',
      // ResolvedByWeek: '',
      // CountByMonth: '',
    },
  },
  WalkTheStore: {
    id: 'c0648b8d-5253-4afc-bcc7-8b77b7d0a4e8',
    category: 'Reporting',
    rerun: true,
    baseDays: 4,
    template: {
      AuditCatalog: false,
      // GenerateSuppressions: false,
      // GenerateRetailReadiness: false,
      GenerateWeeksOfCover: false,
      GeneratePurchaseOrderRollUp: false,
      GenerateBrandHarmonization: false,
      GenerateCasePacks: false,
    },
    description:
      'Provides a list of items ordered by Amazon in the last 365 days, details about those orders, inventory positions, item availability, sales data, and item configuration audits. Only available for US based stores.',
    title: 'Walk The Store',
    hasSpecificData: true,
    result: {
      SalesP1: '',
      SalesP2: '',
      SalesP3: '',
      InventorySourcing: '',
      PurchaseOrders: '',
      ProductAvailability: '',
      WeeksOfCover: '',
      PurchaseOrdersInDollars: '',
      PurchaseOrderInUnits: '',
      Brands: '',
      BrandsByAsin: '',
      CasePacks: '',
      CasePacksToConfirm: '',
    },
  },
  DeepDiveCatalog: {
    id: 'a2c6f1b4-97e2-4b26-bf6f-9f6d7c983c14',
    category: 'Catalog',
    rerun: true,
    baseDays: 4,
    template: {},
    description: '',
    title: 'Deep Dive Catalog',
  },
  ManageImages: {
    id: 'd7b1f7a3-5e13-4a9a-bb2d-65fbc77b6a3d',
    category: 'Content',
    rerun: true,
    baseDays: 4,
    template: {},
    description: '',
    title: 'Manage Images',
  },
};
