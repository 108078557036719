import { useMemo, useState } from 'react';
import { IconButton, Stack } from '@mui/material';

import { Typography, Icon, Dropdown, Tooltip, InfoRow } from '@/components';
import { userStore } from '@/store';
import { PLATFORMS, getPlatformLoginForm } from '@/utils/staticForm';

import styles from './OrganizationContent.module.css';

const PAGE_NAME = 'PlatformsContent';

export const PlatformsContent = ({ editing, logins, setLogins, showError }) => {
  const [selectedPlatform, setSelectedPlatform] = useState(PLATFORMS.none);

  const availablePlatformsIds = [1, 2, 4];

  const platformTypeOption = useMemo(
    () =>
      userStore.platformTypes
        .filter(
          ({ id }) =>
            availablePlatformsIds.includes(id) && !logins.some(({ platform }) => id === platform),
        )
        .map(({ id, name }) => ({
          id,
          value: id,
          label: name,
        })),
    [userStore.platformTypes, logins],
  );

  const handleAddLogin = (platformId) => {
    const newPlatformsData = [...logins, { platform: platformId, configuration: {} }];
    setLogins(newPlatformsData);
    setSelectedPlatform(PLATFORMS.none);
  };

  const handleRemoveLogin = (platformId) => {
    const newPlatformsData = logins.filter(
      (platformLogin) => platformLogin.platform !== platformId,
    );
    setLogins(newPlatformsData);
  };

  const handleChangeLogin = (platformId, configuration) => {
    const newPlatformsData = logins.map((platformLogin) =>
      platformLogin.platform === platformId ? { ...platformLogin, configuration } : platformLogin,
    );
    setLogins(newPlatformsData);
  };

  const handleChangePlatformUsername = (platformId, Username) => {
    const newPlatformsData = logins.map((platformLogin) =>
      platformLogin.platform === platformId ? { ...platformLogin, Username } : platformLogin,
    );
    setLogins(newPlatformsData);
  };

  const handleSelectPlatform = (event) => {
    const platformId = event.target.value;
    if (platformId && platformId !== PLATFORMS.none) {
      handleAddLogin(platformId);
    }
    setSelectedPlatform(PLATFORMS.none);
  };

  return (
    <div className={styles.infoGroupContainer}>
      {logins.map(({ Username, platform, configuration }) => {
        const platformInfo = getPlatformLoginForm(platform);
        const PlatformLogin = platformInfo.form;
        return (
          <div key={platform} className={styles.wrapper}>
            <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2">{platformInfo.name}</Typography>
              {editing && (
                <Tooltip title="Remove Platform" arrow>
                  <IconButton
                    classes={{ root: styles.deleteButton }}
                    onClick={() => handleRemoveLogin(platform)}
                  >
                    <Icon type="close" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <PlatformLogin
              username={Username}
              showError={showError}
              defaultValue={configuration}
              disabled={!editing}
              onUsernameChange={(name) => {
                handleChangePlatformUsername(platform, name);
              }}
              onChange={(configuration) => {
                handleChangeLogin(platform, configuration);
              }}
            />
          </div>
        );
      })}
      {editing && !!platformTypeOption.length && (
        <InfoRow label="Select Platform" labelWidth={120}>
          <Dropdown
            id={`${PAGE_NAME}_platformType`}
            value={selectedPlatform}
            options={platformTypeOption}
            onChange={handleSelectPlatform}
            size="small"
            sx={{ width: 270 }}
          />
        </InfoRow>
      )}
    </div>
  );
};
