import { useMemo, useState } from 'react';
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';

import { Button, DataGridTable, Tooltip, Typography } from '@/components';
import { DEFAULT_SCHEDULE } from '@/utils/constants';
import { SCHEDULE_COLUMNS } from '../tableColumns';
import { useMainStore } from '../../stores/useMainStore';
import { MuiIcons } from '@/utils/types';

import styles from './ScheduleTable.module.css';

const ScheduleTable = () => {
  const { detailStore } = useMainStore();
  const [rowModesModel, setRowModesModel] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const ticketId = detailStore.ticketId;

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleDeleteClick = (id) => async () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, showLoading: true },
    }));
    await detailStore.deleteSchedule(id);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, showLoading: false },
    }));
  };

  const handleSaveClick = (id) => () => {
    const editedRow = detailStore.scheduleList.find((row) => row.id === id);
    if (editedRow.isNew) {
      setIsCreating(false);
    }
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [editedRow.id]: { mode: GridRowModes.View, isNew: false, showLoading: true },
    }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setIsCreating(false);

    const editedRow = detailStore.scheduleList.find((row) => row.id === id);
    if (editedRow.isNew) {
      detailStore.setScheduleList(detailStore.scheduleList.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    let result = newRow;
    try {
      const newData = await detailStore.saveSchedule(newRow);
      result = { ...newData, isNew: false, showLoading: false };
    } catch (err) {
      console.error(err);
    }
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: GridRowModes.View, showLoading: false },
    }));
    return result;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error processing row update:', error);
  };

  const columns = useMemo(
    () => [
      ...SCHEDULE_COLUMNS,
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (rowModesModel[id]?.showLoading) {
            return [
              <Tooltip title="Loading..." arrow>
                <GridActionsCellItem icon={MuiIcons.hourglass} />
              </Tooltip>,
            ];
          }

          if (isInEditMode) {
            return [
              <Tooltip title="Save" arrow>
                <GridActionsCellItem icon={MuiIcons.save} onClick={handleSaveClick(id)} />
              </Tooltip>,
              <Tooltip title="Cancel" arrow>
                <GridActionsCellItem icon={MuiIcons.close} onClick={handleCancelClick(id)} />
              </Tooltip>,
            ];
          }

          return [
            <Tooltip title="Edit" arrow>
              <GridActionsCellItem icon={MuiIcons.pen} onClick={handleEditClick(id)} />
            </Tooltip>,
            <Tooltip title="Delete" arrow>
              <GridActionsCellItem icon={MuiIcons.delete} onClick={handleDeleteClick(id)} />
            </Tooltip>,
          ];
        },
      },
    ],
    [rowModesModel],
  );

  const handleCreateClick = () => {
    const id = 0;
    setIsCreating(true);
    detailStore.setScheduleList([
      ...detailStore.scheduleList,
      { ...DEFAULT_SCHEDULE, id, ticketId, isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <>
      <div className={styles.header}>
        <Typography variant="caption-bold">Schedules</Typography>
        <Button
          id="create-schedule"
          size="small"
          variant="secondary"
          onClick={handleCreateClick}
          disabled={isCreating}
        >
          Create Schedule
        </Button>
      </div>
      {detailStore.scheduleList?.length > 0 && (
        <DataGridTable
          id="scheduleTable"
          rows={detailStore.scheduleList}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          hideFooter
          className={styles.indent}
        />
      )}
    </>
  );
};

export default observer(ScheduleTable);
