import { action, makeObservable, observable } from 'mobx';
import { TICKETS_COLUMNS } from '../components/tableColumns';
import { isCaseInsensitiveEqual } from '@/utils';

export class TableStore {
  hoveredRow = null;
  selectedRow = null;
  rowsPerPage = 100;
  pageNumber = 1;
  sortKey = 'updatedOn';
  sortDirection = 'desc';

  constructor(accountStore, filterStore) {
    makeObservable(this, {
      hoveredRow: observable,
      onMouseEnter: action,
      onMouseLeave: action,

      pageNumber: observable,
      setPageNumber: action,

      selectedRow: observable,
      setSelectedRow: action,

      sortKey: observable,
      sortDirection: observable,
      setSort: action,

      rowsPerPage: observable,
      setRowsPerPage: action,
    });

    this.accountStore = accountStore;
    this.filterStore = filterStore;
  }

  // NOTE: Table actions
  onMouseEnter(logId) {
    this.hoveredRow = logId;
  }

  onMouseLeave() {
    this.hoveredRow = null;
  }

  setSelectedRow(logId) {
    this.selectedRow = logId;
  }

  // NOTE: Sort table rows by order
  setSort(sortKey, direction) {
    if (
      sortKey &&
      direction &&
      TICKETS_COLUMNS().some((column) => isCaseInsensitiveEqual(column.sortKey, sortKey))
    ) {
      this.sortKey = sortKey;
      this.sortDirection = direction;
    }
  }

  // NOTE: Check if current column is active
  isActiveSort(sortKey) {
    return this.sortKey === sortKey;
  }

  getSortDirection(sortKey) {
    if (this.sortKey === sortKey) return this.sortDirection;
    return 'asc';
  }

  // NOTE: Pagination
  setRowsPerPage(rows) {
    this.rowsPerPage = rows;
  }

  setPageNumber(page) {
    this.pageNumber = page;
  }

  dispose() {
    // TBD
  }
}
